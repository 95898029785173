"use client";
import { usePathname, useRouter } from "next/navigation";
import React, { useEffect, useRef } from "react";
import NavBar from "@/components/navbars/NavBar";
import { createClient } from "src/lib/supabase/client";
import useSWRImmutable from "swr/immutable";
import { getTenant } from "./actions";
import FullScreenLoader from "src/components/FullScreenLoader";
import { useStoreActions, useStoreState } from "src/hooks/storeHooks";
import { useUser } from "src/hooks/useUser";
import EventTracker from "@/lib/event-tracking/front-end/eventTrackingManager";
import setLocalStorage from "@/helpers/local-storage/setLocalStorage";
import setCookie from "src/helpers/cookie/setCookie";
import { useSearchParams } from "next/navigation";

export default function StudioLayout({ children }: { children: React.ReactNode }) {
  const pathName = usePathname();
  const setTenant = useStoreActions((action) => action.setTenant);
  // const searchParams = useSearchParams();
  // const fbclid = searchParams?.get('fbclid');
  /**
   * Manage tenancy
   */
  const { data: tenant, isLoading: isTenantLoading } = useSWRImmutable(["tenant"], async () => {
    const { data } = await getTenant(window.location.hostname);
    if (data) setTenant(data);
    return data;
  });

  useEffect(() => {
    if (tenant) {
      setLocalStorage("fb_pixel_id", tenant.facebook_pixel_id);
      setLocalStorage("fb_pixel_access_token", tenant.facebook_access_token);
    }
  }, [tenant]);

  // useEffect(() => {
  //   if (fbclid) setCookie("fbclid", fbclid, 30);
  // }, [fbclid]);

  const effectTriggeredRef = useRef(false);

  useEffect(() => {
    if (effectTriggeredRef.current === true) {
      EventTracker.pageViewed();
    }
  }, [pathName]);

  useEffect(() => {
    if (!effectTriggeredRef.current) {
      effectTriggeredRef.current = true;
      EventTracker.visit();
      EventTracker.pageViewed();
    }
  }, []);

  const { data: profile } = useUser();

  let isShareView =
    pathName?.substring(0, "/share/".length) === "/share/" || pathName?.substring(0, "/pay/".length) === "/pay/";

  const isPodView = pathName?.startsWith("/pod");

  return (
    <>
      {tenant && (
        <>
          {!isShareView && !isPodView && <NavBar productName="unknown" />}

          <div>{children}</div>

          {/*<div className="h-8 p-2">
            <span className="text-sm">
              You are purchasing goods from {tenant.company_name_legal}
            </span>
          </div>*/}
        </>
      )}
      {!tenant && isTenantLoading && <FullScreenLoader />}
      {!tenant && !isTenantLoading && (
        <div className="h-screen w-screen flex items-center justify-center flex-col">
          <img src={"/undraw-illustrations/cancel.svg"} className="w-48" />
          <h3 className="mb-1 mt-4">Oops... a technical error occured</h3>
          <p className="text-xs">The tenant for {window.location.hostname} was not found.</p>
        </div>
      )}
    </>
  );
}
