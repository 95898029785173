"use client";
import React from "react";
import Link from "next/link";

import { ShoppingBagIcon } from "@heroicons/react/24/outline";
import { useStoreState } from "src/hooks/storeHooks";
// import StepIndicator from "./StepIndicator";
import { usePathname, useRouter } from "next/navigation";
import { createClient } from "src/lib/supabase/client";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { ChevronDownIcon, Phone } from "lucide-react";
import { Avatar, AvatarFallback } from "../ui/avatar";
import { AvatarImage } from "@radix-ui/react-avatar";
import { getTenantLogoUrl } from "../../helpers/getTenantLogoUrl";
import ConditionalWrapper from "../conditional-wrapper/ConditionalWrapper";
import { child } from "winston";

interface NavbarProps {
  productName?: string;
}

const NavBar: React.FC<NavbarProps> = ({ productName }) => {
  const router = useRouter();
  const pathName = usePathname();
  const cart = useStoreState((state) => state.cart);
  const isStudioPage = pathName === "/studio";
  const isProductPage = (pathName || "").startsWith("/products/");
  const showStepIndicator = ["/cart", "/user-information", "/summary"].some((path) => (pathName || "").includes(path));

  const profile = useStoreState((state) => state.profile);
  const tenant = useStoreState((state) => state.tenant);
  const client = createClient();

  function signOut(e: any) {}

  return (
    <nav className="flex bg-[#1a1a1a] text-white h-12 sticky top-0 z-50 items-center pr-4 space-x-3">
      {tenant?.logo_from_storage && (
        <div className="h-12 max-w-36 lg:max-w-64 bg-white p-1">
          <Link href={"/"}>
            <img src={getTenantLogoUrl(tenant)} className="max-h-10" />
          </Link>
        </div>
      )}
      {tenant?.display_phone && tenant?.phone_helpline && (
        <div className="ml-2 text-sm flex flex-row items-center space-x-1">
          <span className="hidden mr-0 sm:block sm:mr-1 sm:text-base">Need help?</span>
          <Phone className="h-3.5 w-3.5" />
          <span className="text-xs sm:text-base">{tenant.phone_helpline}</span>
        </div>
      )}
      <div className="grow"></div>
      <ConditionalWrapper
        wrapper={(children) => <Link href={`/cart/${cart?.uuid}`}>{children}</Link>}
        condition={Boolean(cart?.uuid)}
      >
        <div className="flex justify-center items-center space-x-1">
          <ShoppingBagIcon className="h-5 w-5 flex-shrink-0 text-white" aria-hidden="true" />
          <span className="text-sm font-medium text-white">{cart?.line_items.length || 0}</span>
        </div>
      </ConditionalWrapper>

      {/* Profile dropdown */}
      {profile && (
        <Menu as="div" className="relative">
          <MenuButton className="-m-1.5 flex items-center p-1.5">
            <span className="sr-only">Open user menu</span>
            <Avatar className="bg-white text-black h-8 w-8">
              <AvatarFallback>
                {profile.full_name?.split("")[0]}
                {profile.full_name &&
                  profile.full_name?.split(" ").length >= 2 &&
                  `${profile.full_name?.split(" ")[1].split("")[0]}`}
              </AvatarFallback>
            </Avatar>
          </MenuButton>
          <MenuItems
            transition
            className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
          >
            {[
              { href: "/orders", name: "My Orders" },
              { href: "/invoices", name: "My Invoices" },
              { href: "/settings", name: "Settings" },
              { name: "Sign out", onClick: signOut },
            ].map((item) => (
              <MenuItem key={item.name}>
                <Link
                  href={item.href || "#"}
                  onClick={(e) => {
                    if (item.onClick) item.onClick(e);
                  }}
                  className="block px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50"
                >
                  {item.name}
                </Link>
              </MenuItem>
            ))}
          </MenuItems>
        </Menu>
      )}

      {/*<div className="absolute left-1/2 transform -translate-x-1/2">
        {isStudioPage && <p>Select a pre-engineered garment</p>}
        {isProductPage && (
          <>
            <p>{productName}</p>
            <Link href="/studio">
              <button className="bg-transparent text-white border border-gray-200 px-1 py-0 rounded text-sm cursor-pointer font-semibold hover:border-white hover:border-1 transition-all duration-200">
                Change
              </button>
            </Link>
          </>
        )}
      </div>
      {showStepIndicator && cart && (
        <div className="absolute left-1/2 transform -translate-x-1/2">
          <StepIndicator />
        </div>
      )}*/}
    </nav>
  );
};

export default NavBar;
